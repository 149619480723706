import React from 'react';
import {
	HashRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';

//views
import Login from './Views/Login/Login';
import NotFound404 from './Views/404/NotFound404';
import Home from './Views/Home/Home';

function RouterView() {
	return (
		<Router>
			<Switch>
				{/* <Route exact path="/login">
					<Login />
				</Route> */}
				<Route exact path="/">
					<Home />
				</Route>
				<Route path="*">
					<NotFound404 />
				</Route>
			</Switch>
		</Router>
	);
}

export default RouterView;
