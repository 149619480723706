import React from 'react';

export default function NotFound404() {
	return (
		<div className="container">
			<div className="row justify-content-center hv-75">
				<div className="col-md-6">
					<h1
						className="text-center"
						style={{ fontSize: '180px', fontWeight: 700 }}
					>
						404
					</h1>
					<h4 className="text-center">
						Página web no encontrada
					</h4>
				</div>
			</div>
		</div>
	);
}
