import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SVG360 from '../../Assets/Images/eccomerce-360.svg';
import SVGWEB from '../../Assets/Images/eccomerce-web.svg';
import SVGENTER from '../../Assets/Images/enter.svg';
import SVGREPLAY from '../../Assets/Images/replay.svg';
import './Home.css';

export default function Home() {
	const [login, setLogin] = useState(false);
	const [endVideo, setEndVideo] = useState(false);
	const video = useRef(null);

	useEffect(() => {
		login
			? changeVideo(video)
			: (video.current.src = `${process.env.PUBLIC_URL}/video/comp_1.mp4`);
	}, [login]);

	const changeVideo = (video) => {
		video.current.src = `${process.env.PUBLIC_URL}/video/comp_2.mp4`;
		video.current.muted = false;
		video.current.loop = false;
	};

	const playVideo = () => {
		video.current.play();
	};

	return (
		<div className="container-fluid vh-100">
			<div className="row">
				<div className="col-md-12">
					<video
						autoPlay
						muted
						ref={video}
						className="video-background"
						onEnded={(e) => {
							console.log(e.target.src);
							setEndVideo(true);
						}}
						onPlay={(e) => {
							setEndVideo(false);
						}}
					>
						<source
							src={`${process.env.PUBLIC_URL}/video/comp_1.mp4`}
							type="video/mp4"
						/>
					</video>
				</div>
			</div>
			{login ? (
				<div className="container-fluid d-flex flex-column justify-content-end h-100">
					{!endVideo || (
						<div className="row justify-content-center w-100 mb-2">
							<button
								type="button"
								className="btn btn-primary btn-lg btn-orange-color shadow rounded-circle p-0"
								style={{ width: 55, height: 55 }}
								onClick={playVideo}
							>
								<img src={SVGREPLAY} alt="" width="20" />
								<p style={{ fontSize: 10, fontWeight: 700 }}>
									Repetir
								</p>
							</button>
						</div>
					)}
					<div className="row justify-content-center w-100">
						<div className="col-md-5">
							<a
								href="https://ferreteriaonlinehoyostools.com/#!/app/login"
								target="_blank"
								rel="noopener noreferrer"
								className="btn btn-primary btn-block btn-large text-left btn-orange shadow mb-2"
							>
								<img
									src={SVGWEB}
									width="50"
									alt=""
									className="mr-2 "
								/>
								<b style={{ fontSize: '20px' }}>
									Ecommerce Online
								</b>
							</a>
						</div>
						<div className="col-md-5">
							<a
								href={`https://showferretero.com/tour-360/`}
								target="_blank"
								rel="noopener noreferrer"
								className="btn btn-primary btn-block btn-large text-left btn-orange shadow mb-2"
							>
								<img
									src={SVG360}
									width="50"
									alt=""
									className="mr-2"
								/>
								<b style={{ fontSize: '20px' }}>
									Shopping Virtual
								</b>
							</a>
						</div>
					</div>
				</div>
			) : (
				<div className="row py-4 justify-content-end align-items-end h-100">
					<div className="col-md-5 d-flex justify-content-center align-items-center">
						<button
							className="btn btn-primary btn-block btn-large btn-orange-color shadow d-flex justify-content-center"
							style={{ maxWidth: 300 }}
							onClick={() => {
								setLogin(true);
							}}
						>
							<img
								src={SVGENTER}
								width="40"
								alt=""
								className="mr-2 "
							/>
							<b
								style={{ fontSize: '30px', marginBottom: '0px' }}
							>
								Entrar al lobby
							</b>
						</button>
					</div>
				</div>
			)}
		</div>
	);
}
